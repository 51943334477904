import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section className="bg-white border-b py-8 pt-24">
            <div className="container max-w-5xl mx-auto m-8">
              <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
                It looks like that page went missing.
              </h2>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>

              <div className="flex flex-wrap text-center">
                <div className="w-5/6 m-auto p-6">
                  <p className="text-gray-600 mb-8">
                      Head back over to <a href="/" className="underline text-orange-500">https://finding.beer</a>.
                  </p>
                </div>
                <div className="w-full sm:w-1/3 pt-2">
                  
                </div>
              </div>
            </div>
          </section>
    </Layout>
  );
}

export default NotFoundPage;
